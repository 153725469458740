import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import AllRoutes from "./Components/Routes";

import Store from "./Store/Store";
import { Toaster } from "react-hot-toast";

import Gleap from "gleap";

// Please make sure to call this method only once!
Gleap.setUseCookies(true);
Gleap.initialize("vI5VUG0MUvgFmdmMRQUn9mFKCShwPA7w");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Store>
    <React.StrictMode>
      <BrowserRouter>
        <AllRoutes />
      </BrowserRouter>
      <Toaster
        position="bottom-right"
        gutter={12}
        containerStyle={{ margin: "8px" }}
        toastOptions={{
          success: {
            duration: 5000,
            style: {
              backgroundColor: "#2F3EAC",
              color: "#fff",
            },
            iconTheme: {
              primary: "#fff",
              secondary: "#2F3EAC",
            },
          },
          error: {
            duration: 5000,
            style: {
              backgroundColor: "#d32f2f",
              color: "#fff",
            },
            iconTheme: {
              secondary: "#d32f2f",
              primary: "#FFFAFA",
            },
          },
          style: {
            fontSize: "14px",
            padding: "12px 16px",
            borderRadius: "10px",
            backgroundColor: "#2F3EAC",
            color: "#fff",
            maxWidth: "400px",
          },
        }}
      />
    </React.StrictMode>
  </Store>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
